import "../styles/Header.css";


function Header(){
 return(
    <div className="background-container">
    <div className="background-overlay"></div>
    <div className="content">
      <h1 className="headertitle1">YOUR TRUSTED &</h1>
      <h1 className="headertitle2">EXPERIENCED</h1>
      <h1 className="headertitle3">LEGAL PARTNER</h1>
    </div>
  </div>
);
}
export default Header